.wmc-background {
  background-image: url(https://ok11static.oktacdn.com/fs/bco/7/fs0nco2l1L7Jy43wz4x6);

  min-height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;
}


.overview-layout {
  display: grid;
  place-items: center;
}

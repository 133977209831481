.AppIcon {
    cursor: pointer;
    margin: 10px;

    .IconWrapper {
        width: 100px;
        height: 100px;
        border: 1px solid lightgrey;
        display: grid;
        border-radius: 15px;
        place-items: center;
        font-size: 45px;
    }

    .IconLabel {
        width: 100px;
        text-align: center;
        padding-top: 5px;
    }


}

